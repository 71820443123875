<template>


<ClientOnly>
  <div 
    v-if="debugList?.length"
    :class="{debug: true, active: state}"
    >
        
      <div class="btn_debug_act" v-if="!state" @click="start">
        open
      </div>

      <div class="btn_debug_act" v-if="state" @click="finish">
        close
      </div>
      
      <div class="items" v-show="!opened">
  
        <div 
          v-for="(opt, ind) in debugList"
          :key="ind"
          class="item" @click="open(opt, ind)">
            <div class="name">
              <div class="url">{{ opt.code }} - {{ opt.details?.url }}</div>
              <div class="times">{{ opt.cache ? 'CACHE - ' : '' }} {{ opt?.details?.times }}s</div>
            </div>
        </div>
      </div>

      <!-- opened panel -->

      <div class="opened" v-if="opened">


        <div class="ttl" v-if="opened">
          {{ opened.url }}
        </div>
        
        <div class="tabs">
          <div @click="close" class="tab close">
            close
          </div>
          <div 
            v-for="(opt, ind) in tabs"
            :key="ind"
            :class="'tab ' + (opt.id == opened_tab ? 'active' : '')" 
            @click="openTab(opt.id)"
            >
            {{  opt.id }}
            <span v-if="Array.isArray(opened?.details[opt.id])">
              ({{ opened?.details[opt.id].length }})
            </span>          
          </div> 

        </div>

        <!-- response -->
        
        <div class="tab_content response" v-if="opened_tab == 'response'"> 
          <pre> {{ opened }}  </pre>
        </div>


        <!-- in -->
        
        <div class="tab_content in" v-if="opened_tab == 'in'"> 
          <pre> {{ opened?.details.in | pretty }}  </pre>
        </div>
  

        <!-- inp -->
        
        <div class="tab_content inp" v-if="opened_tab == 'inp'"> 
          <pre> {{ opened?.details.inp | pretty }}  </pre>
        </div>

        <!-- trace -->
        
        <div class="tab_content trace" v-if="opened_tab == 'trace'">

          <div 
            v-for="(opt, ind) in opened?.details.trace"
            :key="ind"
            class="row"                  
            >
            {{  opt }}
          </div> 
  
        </div>

        <!-- error -->
        
        <div class="tab_content error" v-if="opened_tab == 'error'">

          <div 
            v-for="(opt, ind) in opened?.details.error"
            :key="ind"
            class="row"                  
            >
            {{  opt }}
          </div> 
  
        </div>

        <!-- sql -->
  
        <div class="tab_content sql" v-if="opened_tab == 'sql'">

          <h2>total time: {{ calcTotalTime(opened?.details.sql) }}</h2>

          <div 
            v-for="(opt, ind) in opened?.details.sql"
            :key="ind"
            :class="{'row': true, 'cache': opt.cache}"                  
            >
            <div class="ttl">
              {{ opt.state }}: 
              <template v-if="opt.cache">
                CACHE
              </template>
              <template v-else>
                {{ opt.end - opt.start }} ({{ opt.start }} - {{ opt.end }})
                ({{ opt.fetch }})
              </template>
            </div>
            <div class="error">
              {{ opt.error }}
            </div>
            <div class="stack">
              {{ opt.stack }}
            </div>
            <div class="scr">
              {{ opt.sql }}
            </div>
            <div class="binds">
              {{ opt.bind }}
            </div>
          </div> 
  
        </div>

        <!-- elastic -->
  
        <div class="tab_content elastic" v-if="opened_tab == 'elastic'">

          <h2>total time: {{ calcTotalTime(opened?.details.elastic) }}</h2>

          <div 
            v-for="(opt, ind) in opened?.details.elastic"
            :key="ind"
            class="row"                  
            >
            <div class="ttl">
              {{ opt.state }}: 
              {{ opt.end - opt.start }} ({{ opt.start }} - {{ opt.end }})
            </div>
            <div class="error">
              {{ opt.message }}
            </div>
            <div class="stack">
              {{ opt.stack }}
            </div> 
            <div class="scr">
              {{ opt.query }}
            </div> 
          </div> 
  
        </div>

      </div>



  </div>
</ClientOnly>
</template>


<script setup>

// const conf = useRuntimeConfig();

// const { debugList }  = useDebugList();

const debugList  = useDebugListFor();
const opened_key = ref(null);
const opened_tab = ref('response');
const opened = ref(null);
const state = ref(false);
const tabs = ref([
  {id: 'response'},
  {id: 'sql'},
  {id: 'in'}, 
  {id: 'inp'},
  {id: 'trace'},
  {id: 'error'},
  {id: 'elastic'},
]);


// const tab_time = computed(() => {
//   for (const m of route.matched)
//     if (m.path.indexOf('edit') >= 0)
//       return false;   
//   return true;
// });


const calcTotalTime = (list) => {
  let total = 0;
  for (const item of list)
    total += item?.end - item?.start || 0;
  return total;
}

const start = () => {
  state.value = true;
}

const finish = () => {
  state.value = false;
  opened_key.value = null;
  opened.value = null;
}

const open = (item, key) => {
  opened_key.value = key;
  opened.value = item;
}

const close = () => {
  opened_key.value = null;
  opened.value = null;
}

const openTab = (tab) => {
  opened_tab.value = tab;
}

const pretty = (tab) => {
  return JSON.stringify((tab), null, '\t');
}


</script>
 


<style>
   
.debug{
  height: 25px;
  width: 46px;
  overflow: hidden;
  font-size: 14px;
    box-sizing: border-box;
}
.debug .btn_debug_act{
  text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
.debug .btn_debug_act:hover{
  background-color: greenyellow;
}
.debug.active{
  height: auto;
  width: auto;
  overflow: auto;
  width: 100%;
  padding: 10px;
  top: 0;  
}

.debug{
  position: fixed;
  background-color: #bcffcd;
  bottom: 0;
  left: 0;
  z-index: 100000;
}
.debug .items{}
.debug .items .item{
  cursor: pointer;
  width: 100%;
}
.debug .items .item:hover {
  background-color: greenyellow;
}
.debug .items .item .name{
  display: flex;
    align-items: flex-start;
}
.debug .items .item .name .url{
  font-weight: bold;
}
.debug .items .item .name .times{
  margin-left: auto;
}

.debug .opened .ttl{
  font-weight: bold;
}

.debug .tabs{
  display: flex;
  justify-content: flex-start;
}
.debug .tabs .tab{
  padding: 3px 10px;
  text-decoration: underline;
  cursor: pointer;
}
.debug .tabs .tab.active{
  font-weight: bold;
}
.debug .tabs .tab:hover{
  background-color: greenyellow;
}
.debug .tabs .close{
  margin-right: auto
}


.tab_content.trace .row{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px gray solid;
}
.tab_content .row.cache{
  opacity: 0.5;
}
.tab_content.elastic .row,
.tab_content.sql .row{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px gray solid;
}


@media all and (max-width: 768px) {
  
}

</style>