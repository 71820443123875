<template>

  <div 
    v-if="list"
    class="popup">  
  
    <div 
      v-for="(p, pk) in list"
      :key="pk"
      :class="'item ' + p.type" 
      @click="remove(p.id)">
      {{ p.message }}
    </div>

  </div>

</template>


<script setup>

const { list } = usePopupStore();
const { $popup } = useNuxtApp();

const remove = (id) => {
  $popup.remove(id)
}


</script>
 

 
<style scoped>
   
.popup{
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1000000000;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;

  align-items: center;
  justify-content: flex-end;  
}
.popup .item{
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  z-index: 1000000000000;
  
  width: 100%;
  bottom: auto;
  right: auto;
  opacity: 0.95;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: 0px 0px 60px rgb(0 0 0 / 20%);
  background-color: #f6f6f6;
  
}
.popup .item.error{
  background-color: #e74819;
  color: white;
}
.popup .item.success{
background-color: #6cb043;
  color: white;
}
@media all and (max-width: 768px) {
  
}

</style>